.anime-card {

    padding: 0;
    background: #2a2c31;
    position: relative;

    z-index: 0;

    & a {
        color: #fff;
        text-decoration: none !important;
        outline: 0;
    }

    .anime-poster {
        padding-bottom: 148%;
        font-size: 0;
        width: 100%;
        position: relative;
        overflow: hidden;
        background-color: rgba(255, 255, 255, .1);

        &:after {
            content: "";
            position: absolute;
            top: 40%;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
            background: #2a2c31;
            background: linear-gradient(0deg, #2a2c31 0, rgba(42, 44, 49, 0) 76%);
        }

        &:hover {

            .anime-poster-img {
                transform: scale(1.2);
            }

            .anime-poster-ahref {
                opacity: 1;
            }
        }

        .tags {
            position: absolute;
            bottom: 3px;
            z-index: 3;
            font-size: 0.8rem;

            &.ltr {
                left: 10px;

                &>* {
                    margin-right: 2px;
                }
            }

            &.rtl {
                right: 10px;

                &>* {
                    margin-left: 2px;
                }
            }
        }

        .anime-poster-img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform 0.2s ease-in-out;

        }

        .anime-poster-ahref {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 3;
            opacity: 0;
            transition: opacity 0.2s ease-in-out;

            &:after {
                content: "";
                position: absolute;
                top: auto;
                right: 0;
                left: 0;
                bottom: 0;
                height: 100%;
                z-index: 1;
                background: #2a2c31;
                background: linear-gradient(0deg, #2a2c31 0, rgba(42, 44, 49, .7) 100%);
            }

            .play {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 2;
                color: #fff;
                height: 100%;
            }
        }

    }

    .anime-detail {
        color: #aaa;
        font-size: 12px;
        padding: 10px;
        min-height: 84px;

        .anime-name {
            font-size: 14px;
            line-height: 1.3em;
            margin: 0;
            font-weight: 500;
            margin-bottom: 8px;
            height: 37px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .anime-info {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;

            &>* {
                white-space: nowrap;

                &:not(:last-child):after {
                    content: " · ";
                }
            }
        }
    }
}